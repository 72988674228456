import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
}));

function MenuComponent() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const history = useHistory();

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
					<Tab label="Home" onClick={()=>{
						history.push("/home");
					}}/>
					<Tab label="Products" onClick={()=>{
						history.push("/products");
					}}/>
					<Tab label="Contact" onClick={()=>{
						history.push("/contact");
					}}/>
					<Tab label="Admin" onClick={()=>{
						history.push("/login");
					}}/>
				</Tabs>
			</AppBar>
		</div>
	);
}

export default MenuComponent;
